// import { StrictMode } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <StrictMode>
  // <RelayEnvironmentProvider environment={RelayEnvironment}>
  <App />,
  // </RelayEnvironmentProvider>
  // </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
