import Logo from "./Logo";

export default function MainLoading() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <Logo />
        <div className="horizontal-progress"></div>
      </div>
    </div>
  );
}
