import { Suspense, lazy, Component, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./registerServiceWorker";
import MainLoading from "MainLoading";
const RelayEnclosedApp = lazy(() => import("RelayEnclosedApp"));

class Error extends Component {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    // @ts-ignore
    if (this.state.errorInfo) {
      return <h1>Service not available</h1>;
    }
    // @ts-ignore
    return this.props.children;
  }
}

function App() {
  useEffect(() => {
    let htmlLoader = document.querySelector("#html-loader");
    if (htmlLoader) {
      htmlLoader.remove();
    }
  }, []);

  return (
    // @ts-ignore
    <BrowserRouter>
      <Suspense fallback={<MainLoading />}>
        <Error>
          {/* @ts-ignore */}
          <Switch>
            {/* @ts-ignore */}
            <Route path={"/"} component={RelayEnclosedApp} />
          </Switch>
        </Error>
      </Suspense>
    </BrowserRouter>
  );
}

function updateWorker(register: any) {
  if (register) {
    register.waiting.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
    //updateAvailable()
  }
}

serviceWorker.register({ onUpdate: updateWorker });

export default App;
