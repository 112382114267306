import * as React from "react";
const SVGComponent = (props) => (
  <svg id="svg2" height={"8vw"} viewBox="0 0 534.56 126.66667" {...props}>
    <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,126.66667)">
      <g id="g10" transform="scale(0.1)">
        <path
          d="m 657.984,357.305 c -0.812,25.39 -6.183,50.031 -14.281,73.879 -15.918,46.921 -37.988,90.379 -75.469,124.343 -9.121,9.411 -17.496,19.703 -27.48,28.078 -55.781,46.782 -120.695,70.665 -192.918,76.676 -16.215,1.356 -20.5,-4.363 -17.606,-18.996 1.985,-10.015 3.063,-20.215 5.329,-30.156 C 350.629,545 393.625,500.691 449.227,466.328 v 0 c 9.679,-11.367 20.398,-22.012 28.875,-34.211 32.824,-47.262 38.566,-99.324 25.363,-154.336 v 0 0 C 491.07,245.586 474.293,216.438 447.648,193.516 403.273,155.332 351.48,145.266 295.02,151.758 204.566,178.02 154.84,237.324 148.727,332.012 c 0.027,79.519 0.05,159.039 0.074,238.558 -0.121,80.719 -0.246,161.442 -0.367,242.164 -0.977,7.11 -1.543,14.301 -2.989,21.313 C 132.93,894.766 77.0117,944.25 15.3711,949.742 5.46875,950.625 -0.140625,949.859 0,937.211 0.800781,865.621 0.796875,794.023 1.06641,722.434 1.0625,644.102 1.05078,565.77 1.04688,487.441 0.8125,433.438 0.59375,379.441 0.328125,325.438 0.207031,300.73 3.07422,276.406 9.56641,252.539 v 0 C 30.5586,167.004 77.2539,99.8125 152.219,52.832 v 0 C 202.145,18.2539 258.336,2.16797 318.211,0.1875 516.633,-6.38672 674.012,159.512 657.984,357.305"
          style={{
            fill: "#5761b7",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path12"
        />
        <path
          d="m 889.773,311.836 c 0,-103.223 78.489,-179.57 175.267,-179.57 96.78,0 174.2,76.347 174.2,180.644 0,104.297 -77.42,180.649 -174.2,180.649 -96.778,0 -175.267,-76.352 -175.267,-180.649 z m -140.867,2.148 c 0,150.547 110.754,310.762 316.134,310.762 205.38,0 316.13,-160.215 316.13,-311.836 C 1381.17,160.215 1270.42,0 1065.04,0 859.66,0 748.906,160.215 748.906,312.91 v 1.074"
          style={{
            fill: "#5761b7",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path14"
        />
        <path
          d="m 1494.07,795.715 h 140.87 V 365.598 l 203.23,224.734 h 168.81 L 1761.82,330.117 2008.06,0 H 1846.77 L 1634.94,294.629 V 0 h -140.87 v 795.715"
          style={{
            fill: "#5761b7",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path16"
        />
        <path
          d="M 2091.93,590.332 H 2232.8 V 0 h -140.87 z m 0,205.383 H 2232.8 V 669.902 h -140.87 v 125.813"
          style={{
            fill: "#5761b7",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path18"
        />
        <path
          d="m 2371.5,590.332 h 132.27 v -74.195 c 12.9,26.883 69.89,92.476 172.04,92.476 48.4,0 102.16,-13.98 145.17,-60.215 12.89,-13.98 27.95,-38.71 35.48,-53.765 10.75,18.277 23.66,37.633 43.02,55.914 36.55,34.41 88.16,58.066 150.53,58.066 49.47,0 111.83,-12.906 156.99,-59.14 53.77,-55.918 64.52,-104.297 64.52,-225.813 V 0 h -140.86 v 318.281 c 0,37.637 -3.23,78.496 -23.66,109.688 -15.05,29.023 -43.01,48.383 -92.47,48.383 -49.46,0 -83.88,-21.508 -102.16,-53.762 -18.27,-32.258 -20.43,-63.449 -20.43,-98.93 V 0 h -140.86 v 323.66 c 0,34.406 -3.22,67.746 -18.28,97.856 -17.21,31.179 -46.23,53.761 -96.78,54.836 -51.6,0 -83.87,-24.731 -101.07,-54.836 -17.21,-31.184 -22.58,-67.746 -22.58,-102.161 V 0 H 2371.5 v 590.332"
          style={{
            fill: "#5761b7",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path20"
        />
        <path
          d="m 3517.76,311.836 c 0,-103.223 78.49,-179.57 175.26,-179.57 96.78,0 174.2,76.347 174.2,180.644 0,104.297 -77.42,180.649 -174.2,180.649 -96.77,0 -175.26,-76.352 -175.26,-180.649 z m -140.87,2.148 c 0,150.547 110.75,310.762 316.13,310.762 205.38,0 316.14,-160.215 316.14,-311.836 C 4009.16,160.215 3898.4,0 3693.02,0 3487.64,0 3376.89,160.215 3376.89,312.91 v 1.074"
          style={{
            fill: "#5761b7",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
          }}
          id="path22"
        />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
